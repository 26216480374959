@font-face {
    font-family: 'EuroRegular';
    src: local('EuroRegular'), url('./assets/fonts/EuroRegular.ttf') format('truetype');
}

html {
    font-family: 'EuroRegular';
    height: 100%;
    width: 100%;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
}

h1 {
    font-size: 30px;
    letter-spacing: 1px;
    color: #e71754;
    text-align: center;
    text-decoration: underline solid #e71754 3px;
}

h4 {
    color: #112a46;
}

p {
    color: white;
}

button {
    transition-duration: 0.25s;
}
.cta-btn {
    display: inline-block;
    padding: 10px 23px;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
    text-decoration: underline;
    font-weight: bold;
    outline: none;
    color: #e71754;
    background-color: #112a46;
    border: 2px solid white;
    border-radius: 15px;
    box-shadow: 0 6px #999;
}
.cta-btn:hover {
    border: 2px solid #e71754;
}
.cta-btn:active {
    color: white;
    text-decoration: none;
    text-shadow: 1px 1px #112a46;
    background-color: #e71754;
    border: 2px solid #112a46;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

/* ----------------------------------------- */

/* STYLE FOR BIGGER DISPLAY FORMATS ONLY: */
@media only screen and (min-width: 768px) {
    main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        min-height: 88vh;
    }

    h1 {
        font-size: 48px;
    }

    .cta-btn {
        color: #e71754;
        background-color: #112a46;
        padding: 9px 12px 12px 12px;
        font-size: 18px;
        font-weight: bold;
        border: 2px solid rgb(165, 165, 165);
        border-radius: 20px;
    }
    .cta-btn:hover {
        color: #e71754;
        border: 2px solid #e71754;
        text-decoration: underline;
    }

    a {
        font-size: 20px;
    }
}
