/* ----------------------------------------------- */
/* #aboutsection-main-container,
#aboutsection-title,
#aboutsection-text1,
#aboutsection-img1-div,
#aboutsection-btn1-box,
#aboutsection-horizontal-divider-box,
#aboutsection-text2,
#aboutsection-img2-div,
#aboutsection-btn2-box {
    border: 1px solid black;
} */
/* ----------------------------------------------- */

#aboutsection-main-container {
    /* height: 85vh; */
    width: 100%;

    text-align: center;
    background-color: #112a46;
}

#aboutsection-btn1-box,
#aboutsection-btn2-box {
    padding: 10px 0;
}
#aboutsection-btn2-box {
    margin-bottom: 20px;
}

/* #aboutsection-text1,
#aboutsection-text2 {
    flex-direction: column;
    
} */

#aboutsection-img2-div,
#aboutsection-btn2,
.aboutsection-horizontal-divider,
.img-desktop {
    display: none;
}

/* ----------------------------------------------- */
/* STYLES FOR BIGGER DISPLAY FORMATS: */
@media only screen and (min-width: 768px) {
    #aboutsection-img1-div,
    #aboutsection-img2-div,
    #aboutsection-btn2,
    /* #aboutsection-horizontal-divider-box,
    .aboutsection-horizontal-divider, */
    .img-desktop {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .img-mobile {
        display: none;
    }
    .img-desktop {
        height: 95%;
        width: 95%;
        object-fit: contain;
        /* border: 4px solid white; */
    }

    /* NAMING DE CHAQUE PARTIE DE LA GRID POUR AFFICHAGE FORMAT DESKTOP: */
    #aboutsection-title {
        grid-area: about-title;
    }
    #aboutsection-text1 {
        grid-area: about-text1;
    }
    #aboutsection-img1-div {
        grid-area: about-img1;
    }
    #aboutsection-btn1-box {
        grid-area: about-btn1;
    }
    /* #aboutsection-horizontal-divider-box {
        grid-area: about-divider;
    } */
    #aboutsection-text2 {
        grid-area: about-text2;
    }
    #aboutsection-img2-div {
        grid-area: about-img2;
    }
    #aboutsection-btn2-box {
        grid-area: about-btn2;
    }

    /* CRÉATION DE LA GRID POUR AFFICHAGE FORMAT DESKTOP: */
    #aboutsection-main-container {
        /* height: 100vh; */

        display: grid;

        /* gap: 2px 10px; */
        /* padding: 10px; */

        grid-template-columns: 50% 50%;
        grid-template-rows: auto auto auto auto auto auto auto auto;
        grid-template-areas:
            'about-title about-img1'
            'about-text1 about-img1'
            'about-text1 about-img1'
            'about-btn1 about-img1'
            'about-text2 about-img2'
            'about-text2 about-img2'
            'about-text2 about-img2'
            'about-btn2 about-img2';
    }

    #aboutsection-horizontal-divider-box {
        margin-top: -20px;
    }
    .aboutsection-horizontal-divider {
        width: 50%;
        border: 2px solid #e71754;

        position: relative;
        top: 0;
    }

    #aboutsection-main-container > div {
        background-color: #112a46;
        text-align: center;
        padding: 15px;
    }
}
/* ----------------------------------------------- */
