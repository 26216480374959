/* COMMON STYLES */
#carte-resto-main {
    width: 100%;

    background: rgb(255, 255, 255);
    background: -moz-radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(227, 227, 227, 1) 85%);
    background: -webkit-radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(227, 227, 227, 1) 85%);
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(227, 227, 227, 1) 85%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#e3e3e3",GradientType=1);
}

#menu-title,
#galerie-btn-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

#menu-title {
    margin-top: 20px;
}

#galerie-btn-div > a > button {
    margin-bottom: 20px;
}
/* ----------------------------------------- */

/* STYLES FOR SMALL DISPLAY FORMATS ONLY: */
@media only screen and (max-width: 767px) {
    #carte-resto-main {
        height: 84vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    #menu-title,
    #galerie-btn-div {
        height: 15%;
        width: 100%;
    }

    /* CACHER LE CAROUSEL SUR MOBILE */
    .menu-carousel {
        display: none;
    }
}
/* ----------------------------------------- */

/* STYLES FOR BIGGER DISPLAY FORMATS ONLY: */
@media only screen and (min-width: 768px) {
    #carte-resto-main {
        height: 88vh;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 15% 70% 15%;
    }
    #menu-title {
        grid-area: 1 / 1 / span 1 / span 2;
    }
    .carte-links-container {
        grid-area: 2 / 1 / span 1 / span 1;
    }
    #galerie-btn-div {
        grid-area: 3 / 1 / span 1 / span 2;
    }

    /* STYLES A SUPPRIMER !!! */
    /* .menu-carousel {
        color: red;
        background-color: blue;
        border: 1px solid red;
    } */
}
/* ----------------------------------------- */
