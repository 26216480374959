.menu-subsection-container {
    border: 1px solid grey;
}

.menu-subsection-container,
.subsection-meals-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menu-subsection-container {
    margin: 16px;

    /* width: 100%; */
}

.menu-subsection-title {
    color: #e71754;
    text-decoration: underline solid #e71754 2px;
}

.subsection-meals-list {
    width: 95%;
}

.subsection-meals-list > h3 {
    margin-bottom: 0;
}

.menu-item {
    width: 95%;
    /* margin-top: 10px; */

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.menu-left {
    display: flex;
    max-width: 80%;
}

.losange-menu-subsection {
    height: 16px;
    margin: 25px 16px 0px 6px;
}

.meal-name {
    max-width: 100%;
}

.ingredients {
    max-width: 75%;
    margin-top: -16px;
    line-height: 100%;
    font-size: 14px;
    text-align: left;

    color: #112a46;
}

#ingredients-pizza {
    max-width: 100%;
}

.details-menu {
    margin-top: -16px;
    font-size: smaller;
}
.details-poisson {
    margin-top: -20px;
    margin-left: 6px;
    font-size: smaller;
}

.menu-right {
    margin-left: 14px;
    margin-right: 8px;
}

.menu-right-pizza {
    display: flex;
    margin-left: 14px;
    margin-right: 8px;
    height: 20px;
}

.rappel {
    margin-left: 6px;
    font-size: smaller;
    font-weight: lighter;
}

.rappel-viandes {
    margin-top: -20px;
    margin-left: 6px;
    font-size: smaller;
    font-weight: lighter;
}

.pizza-pricing-categories {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    /* margin-right: 14px; */
}

.petite-pizz {
    margin-right: 12px;
}

.price-petite {
    margin-right: 20px;
}

.retour-vers-carte {
    margin: 20px 0;
}

.dessert-subtitle {
    width: 100%;
}

.dessert-subtitle > h3 {
    color: #112a46;
    text-align: center;
    border: 1px solid #112a46;
}

.pricing-info {
    display: flex;
    width: 100%;
    height: 24px;
    font-size: small;
    justify-content: center;
    align-items: center;
    background-color: grey;
    margin-bottom: 16px;
    margin-left: 0;
}

@media only screen and (min-width: 768px) {
    /* .menu-main {
        display: flex;
        justify-content: center;
        align-items: center;
    } */

    .menu-subsection-container {
        min-width: 50%;
    }

    .ingredients {
        font-size: 15px;
    }

    h4 {
        font-size: 17px;
    }
}
