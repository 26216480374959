.page-histoire-titre {
    grid-area: titre;
}
.histoire-pic1 {
    grid-area: pic1;
}
.page-histoire-text1 {
    grid-area: text1;
}
.histoire-pic2 {
    grid-area: pic2;
}
.page-histoire-text2 {
    grid-area: text2;
}
.page-histoire-btn-area {
    grid-area: btn;
}

.page-histoire-main {
    text-align: center;
}

.page-histoire-grid {
    display: grid;
    grid-template-areas:
        'titre titre titre titre titre titre'
        'pic1 pic1 pic1 pic1 pic1 pic1'
        'text1 text1 text1 text1 text1 text1'
        'pic2 pic2 pic2 pic2 pic2 pic2'
        'text2 text2 text2 text2 text2 text2'
        'btn btn btn btn btn btn';

    gap: 10px;
    background-color: #112a46;
}

[class*='histoire-pic'] {
    width: 100%;
}
[class*='histoire-text'] {
    color: white;
    margin-top: 10px;
    padding: 0px 10px 0px 10px;
    /* width: 100%; */
}

.page-histoire-btn-area {
    margin-bottom: 26px;
}

@media only screen and (min-width: 768px) {
    .page-histoire-grid {
        display: grid;
        grid-template-areas:
            'titre titre titre pic1 pic1 pic1'
            'text1 text1 text1 pic1 pic1 pic1'
            'text2 text2 text2 pic2 pic2 pic2'
            'btn btn btn pic2 pic2 pic2';
    }
    [class*='histoire-pic'] {
        width: 95%;
        height: 50vh;
        object-fit: cover;
    }
}
