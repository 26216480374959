.carte-links-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.carte-link-btn > a {
    color: #112a46;
    text-decoration: none;
}

@media only screen and (max-width: 767px) {
    .carte-links-container {
        height: 70%;
        width: 100%;
    }
}

@media only screen and (min-width: 768px) {
    /* .carte-links-container {
        width: 50%;
    } */

    .carte-link-btn > a:hover {
        font-weight: bold;
        text-decoration: underline solid #e71754 2px;
        text-shadow: 1px 1px pink;
    }
}
