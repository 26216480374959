#footer-main-container {
    text-align: center;

    color: white;
    background-color: black;
    border-top: 1px solid white;
}

/* GOOGLE MAPS' "iframes" : */
.iframe {
    border: 0;
}

#social-networks-links-mobile {
    margin-top: 50px;
}
#social-networks-links-mobile > a > img {
    width: 50px;
    margin: auto 8px;
}

@media only screen and (max-width: 767px) {
    #iframe-small {
        width: 90%;
    }
    #iframe-medium {
        display: none;
    }

    #footer-block1 {
        padding-top: 40px;
    }

    #footer-block1,
    #footer-block2,
    #footer-block3,
    #footer-block4 {
        margin-bottom: 60px;
    }

    #footer-block4 {
        text-decoration: none;
        color: white;
    }
    #footer-block5 {
        margin-top: -20px;
    }
}

@media only screen and (min-width: 768px) {
    #iframe-small {
        display: none;
    }
    #iframe-medium {
        display: inline;
    }

    #footer-main-container {
        padding-top: 5vh;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto 20px;
    }

    [id^='footer-block'] {
        display: grid;
        justify-content: center;
    }

    #footer-block1 {
        order: 1;
    }
    #footer-block5 {
        order: 2;
    }
    #footer-block3 {
        order: 6;
        /* display: flex;
        flex-direction: column;
        justify-content: space-evenly; */
    }
    #footer-block2 {
        order: 3;
    }
    #footer-block6 {
        order: 5;
    }
    #footer-block4 {
        order: 4;
        color: white;
        text-decoration: none;
        font-size: 16px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
    }

    #social-networks > a > img {
        margin: auto 16px;
    }
    #social-networks > a > img:hover {
        height: 86px;
    }

    #footer-block3 > p {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #footer-block4 > div > a > img {
        margin-bottom: 20px;
    }
    #footer-block4 > a {
        width: 70%;
        padding: 8px;
    }

    #footer-block4 > a:hover {
        border: 1px solid white;
    }
}
