#hide {
    height: 6vh;
    width: 100%;
    position: fixed;
    bottom: 0;

    display: flex;
    justify-content: space-evenly;
    align-items: center;

    background-color: black;
    color: white;
    border-top: 1px solid white;
}

.small-footer-text {
    width: 33%;
    font-size: 12px;
    text-align: center;
    /* margin-left: 6px;
    margin-right: 6px; */
}

#minifooter-arrows-div {
    width: 33%;
    text-align: center;
}
#minifooter-arrows-div > img:hover {
    cursor: pointer;
    width: 46px;
}

.small-footer-text {
    color: #fff;
    /* font-size: 16px; */
    text-decoration: none;
}
/* ----------------------------------------------- */

/* STYLES FOR BIGGER DISPLAY FORMATS: */
@media only screen and (min-width: 768px) {
    .small-footer-text {
        font-size: 14px;
        font-weight: bold;
    }
}
