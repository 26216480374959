.Navbar {
    min-height: 90px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: grey;
    transition: 3s;
    border-bottom: 1px solid white;
}
.sticky {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
    position: sticky;
    top: 0;
    z-index: 100;
}
.Navbar .nav-logo {
    font-weight: 700;
    font-size: 21px;
    margin-left: 15px;
    margin-top: 1px;
    color: #fff;
}
.Navbar > .nav-items > a,
p {
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    margin: 15px;
    position: relative;
    opacity: 0.9;
    text-align: center;
}

.open {
    z-index: 1;
}

.Navbar > .nav-items > a:hover,
.Navbar > .nav-items > p:hover {
    opacity: 1;
    cursor: pointer;
}
.Navbar > .nav-items > a::before,
.Navbar > .nav-items > p::before {
    content: '  ';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 3px;
    background: #fff;
    transition: all 0.45s;
}
.Navbar > .nav-items > a:hover::before,
.Navbar > .nav-items > p:hover::before {
    width: 100%;
}

.Navbar > .nav-toggle {
    display: none;
}

/* STYLES FOR SMALL DISPLAY FORMATS: */
@media only screen and (max-width: 767px) {
    #nav-logo-desktop {
        display: none;
    }
    .Navbar {
        height: 12vh;
    }
    .Navbar .nav-logo {
        margin-left: 4px;
        margin-top: 2px;
    }
    .Navbar > .nav-items {
        text-align: center;
        display: flex;
        flex-direction: column;
        background: #3b4da7;
        position: absolute;
        top: 50px;
        right: 0;
        margin-top: 46px;
        width: 50%;
        transform: translateX(-200%);
        transition: all 0.35s;
        text-decoration: underline white;
        cursor: pointer;
    }

    .Navbar > .nav-items > a::before,
    .Navbar > .nav-items > p::before {
        background: transparent;
    }
    .Navbar > .nav-items.open {
        transform: translateX(0);
    }
    .Navbar > .nav-toggle {
        display: flex;
        width: 60px;
        height: 60px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .nav-toggle > .bar {
        position: relative;
        width: 30px;
        height: 2px;
        background: #ffffff;
        transition: all 0.45s ease-in-out;
    }
    .nav-toggle > .bar::before,
    .nav-toggle > .bar::after {
        content: '  ';
        position: absolute;
        height: 2px;
        background: #ffffff;
        border-radius: 2px;
        transition: all 0.45s ease-in-out;
    }
    .nav-toggle > .bar::before {
        transform: translateY(-8px);
        width: 30px;
        right: 0;
    }
    .nav-toggle > .bar::after {
        width: 30px;
        transform: translateY(8px);
    }
    .nav-toggle.open > .bar {
        transform: translateX(-40px);
        background: transparent;
    }
    .nav-toggle.open > .bar::before {
        width: 30px;
        transform: rotate(45deg) translate(26px, -26px);
    }
    .nav-toggle.open > .bar::after {
        transform: rotate(-45deg) translate(26px, 26px);
    }
}
/* ----------------------------------------------- */

/* STYLES FOR BIGGER DISPLAY FORMATS: */
@media only screen and (min-width: 768px) {
    #nav-logo-mobile {
        display: none;
    }
    .Navbar {
        height: 8vh;
    }
    #nav-logo-desktop {
        margin-top: 4px;
    }
    .Navbar > .nav-items {
        display: flex;
        margin-right: 14px;
    }
    .Navbar > .nav-items > a,
    .Navbar > .nav-items > p {
        font-size: larger;
    }
}
/* ----------------------------------------------- */
