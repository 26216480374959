/* DEFAULT STYLES: */
.landing-carousel {
    overflow: hidden; /* We need to hide the overflow content inside in order to show the active item only */
}

.inner {
    white-space: nowrap; /* This line is really important to keep our items in 1 line otherwise they will break into the new line by default */
    transition: transform 0.3s;
}

.landing-carousel-item {
    display: inline-flex; /* Make sure the display is `inline-flex` or `inline-block` to keep the items in 1 line */
    align-items: center;
    justify-content: center;
}

.landing-carousel-images {
    width: 100%;
    height: 84vh;
    object-fit: cover;
}

.indicators {
    display: flex;
    justify-content: center;

    position: relative;
    bottom: 40px;
}

.indicators > button {
    margin: 5px;
}

.indicators > button.active {
    background-color: white;
}
/* ----------------------------------------------- */

/* STYLES FOR BIGGER DISPLAY FORMATS: */
@media only screen and (min-width: 768px) {
    #landing-carousel-main-container {
        /* Dimensions fixées en % car dépendantes des valeurs fixées dans 'LandingSection.css' -> "#landingsection-main-container"  */

        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .landing-carousel-images {
        height: 88vh;
        object-fit: cover;
        margin-top: 4px;
    }
}

/* ----------------------------------------------- */
