.menu-carousel {
    overflow: hidden;
}

.menu-carousel-inner {
    white-space: nowrap;
    transition: transform 0.3s;
}

.menu-carousel-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.menu-carousel-item > img {
    height: 61.6vh;
    width: 100%;
    /* object-fit: cover; */
    object-fit: contain;
}

.menu-carousel-indicators {
    display: flex;
    justify-content: center;
}
.menu-carousel-indicators > button {
    margin: 5px;
}
.menu-carousel-indicators > button.active {
    background-color: #e71754;
}

/* POUR L'INSTANT ON N'AFFICHE LE CAROUSEL QUE SUR FORMAT DESKTOP */
@media only screen and (min-width: 768px) {
    /* .menu-carousel {
        width: 50%;
    } */
}
