/* #landing-section-main {
    border: 1px solid black;
} */

/* STYLES FOR SMALL DISPLAY FORMATS: */
@media only screen and (max-width: 767px) {
    /* PAS DE 'LANDINGSIDEBLOCK' SUR PETITS ECRANS */
    #landing-sideblock-main {
        display: none;
    }
}

/* STYLES FOR BIGGER DISPLAY FORMATS: */
@media only screen and (min-width: 768px) {
    #landing-section-main {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 2px;
    }
}
