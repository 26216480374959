/* GENERAL STYLES (FOR BOTH DISPLAY FORMATS): */
#homepage-main {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    /* margin: 4px 0; */
    background-color: black;
}

#landing-sideblock-main {
    background-color: white;
}

/* STYLES FOR SMALL DISPLAY FORMATS: */
@media only screen and (max-width: 767px) {
    #landing-section-main {
        height: 84vh;
    }
    #aboutsection-main,
    #menusection-main {
        height: 88vh;
    }
}
/* --------------------------------------------- */

/* STYLES FOR BIGGER DISPLAY FORMATS: */
@media only screen and (min-width: 768px) {
    #landing-section-main {
        height: 88vh;
    }
    #aboutsection-main,
    #menusection-main {
        height: 92vh;
    }
}
/* --------------------------------------------- */
